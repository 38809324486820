import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { useUserInfo } from '../../state/UserContext';

import { useAxios } from '../../services/api';



import {
  Panel,
  ContentContainer,
  CardsContainer,
  StyledTabs,
  StyledTabPane,
} from './styles';
import Title from '../../components/Title';
import { ProductCard, SearchHeader, LazyLoader } from '../../components';

import CategorySection from './CategorySection';

function Itens() {
  const [axiosGet] = useAxios('get');

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);
  const [userInfo] = useUserInfo();

  const [mergedItems, setMergedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [length, setLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryTypeahead, setCategoryTypeahead] = useState('');
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [itemsToAdd, setItemsToAdd] = useState([]);

  const [categories, setCategories] = useState([]);
  const [categoryIcons, setCategoryIcons] = useState([]);

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    if (!userInfo) return;

    const fetchCategories = async () => {
      const { data: categoriesData } = await axiosGet({ url: '/categories' });

      setCategories(categoriesData.data);
    };

    fetchCategories();
  }, [userInfo]);

  useEffect(() => {
    const fetchIcons = async () => {
      const { data: categoryIconsData } = await axiosGet({ url: '/category-icons' });

      setCategoryIcons(categoryIconsData.data);
    };

    fetchIcons();
  }, []);

  useEffect(() => {
    if (!(!length || mergedItems.length !== length)) return;
    setIsLoading(true);

    const fetchItems = async () => {
      const { data: itemsData } = await axiosGet({ url: '/items' });
      const productsResponse = await Promise.all(itemsData.data.map(({ productId }) => (
        axiosGet({ url: `/products/${productId}` })
      )));

      const mergedItemsResult = itemsData.data.map((item, index) => {
        const { data: product } = productsResponse[index]?.data || {};

        if (!product) return null;

        const { image, name, description } = product;

        return {
          ...item,
          image,
          name,
          description,
        };
      });

      setMergedItems(mergedItemsResult);
      setLength(mergedItemsResult.length);
      setIsLoading(false);
      setFilteredItems(mergedItems);
    };

    fetchItems();
  }, [mergedItems]);

  useEffect(() => {
    console.log(search);
    let displayedItems = [...mergedItems];
    if (filter?.sort) {
      displayedItems.sort((a, b) => a.name.localeCompare(b.name));
    } 
    
    if (search) {
      displayedItems = displayedItems.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    }

    setFilteredItems(displayedItems);
  }, [filter, search])

  const [shouldDeleteShow, setShouldDeleteShow] = useState(null);

  return (
    <Panel onClick={() => {
      if (typeof shouldDeleteShow !== 'number') return;

      setShouldDeleteShow(null);
    }}
    >
      <Title title="Itens" />
      <ContentContainer>
        <StyledTabs defaultActiveKey={activeTab} onChange={setActiveTab}>
          <StyledTabPane tab="Itens" key="1" />
          <StyledTabPane tab="Categorias" key="2" />
        </StyledTabs>

        {activeTab === '1' && (
          <>
            <SearchHeader
              filter={filter}
              setFilter={setFilter}
              setSearch={setSearch}
              filterOptions={['Ordenar A-Z']}
              type="item"
            >
              {(active) => (
                active && <Redirect to="/produtos" />
              )}
            </SearchHeader>

            <CardsContainer>
              {isLoading ? (
                <LazyLoader />
              ) : filteredItems.map(({
                _id,
                ...rest
              }) => (
                <ProductCard
                  _id={_id}
                  itemId={_id}
                  isItem
                  setMergedItems={setMergedItems}
                  key={_id}
                  {...rest}
                />
              ))}
            </CardsContainer>
          </>
        )}

        {activeTab === '2' && (
          <CategorySection
            categories={categories}
            setCategories={setCategories}
            categoryIcons={categoryIcons}
            setCategorySuggestions={setCategorySuggestions}
            categorySuggestions={categorySuggestions}
          />
        )}
      </ContentContainer>
    </Panel>
  );
}

export default Itens;
