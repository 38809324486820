import styled from 'styled-components';
import { DatePicker as DatePickerComponent, TimePicker as TimePickerComponent } from 'antd';
import { Checkbox as CheckboxComponent } from 'antd';
import Colors from '../../styles/Colors';

const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  height: 17rem;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .3rem;
  position: relative;

  &:hover {
    .delete-button {
      margin-top: -2.2rem;
      max-height: 2.2rem;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Type = styled.span`
  padding: .1rem .3rem;
  background-color: ${Colors.secondaryBlue};
  color: ${Colors.mainWhite};
  border-radius: .3rem;
  font-size: .6rem;
  margin: .3rem 0;
`;

const Check = styled.img`
  margin: .3rem .5rem;
`;

const Title = styled.h2`
  color: ${Colors.secondaryBlack};
  font-weight: bolder;
  font-size: ${({ isText }) => (isText ? '.8rem' : '1rem')};
  width: 100%;
  margin-bottom: 0;
  margin-top: .6rem;
`;

const Subtitle = styled.h2`
  color: ${Colors.secondaryBlack};
  font-size: .8rem;
  width: 100%;
  height: 1rem;
`;

const TimeInsertionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Time = styled.p`
  color: ${Colors.secondaryBlack};
  font-size: .6rem;
`;

const Image = styled.img`
  max-width: 100%;
  height: 6rem;
  max-height: 6rem;
  object-fit: cover;
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.blue};
  color: ${Colors.blue};
  border-radius: 5px;
  width: 11rem;
  height: 35px;
  outline: none;
  background-color: white;
  padding: 0;
  margin: 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-height: 0rem;
  transition: max-height .3s ease-in-out, margin-top .3s ease-in-out;
  overflow: hidden !important;
  position: absolute;
  bottom: 0;

  @media only screen and (max-width: 600px) {
    max-height: 2.2rem;
  }
`;

const Icon = styled.img`
  margin: 0;
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: ${({ width }) => width};
  opacity: ${({ opacity }) => opacity || 1};

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '.8rem' : '.6rem')};
  }
`;

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.blue};
  margin: .3rem 0;
  height: 6rem;
  overflow: hidden;
`;

const Label = styled.span`
  color: #016ec2;
  text-align: left;
  white-space: nowrap;
`;

const DateSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const DateSubContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const DatePicker = styled(DatePickerComponent)`
  border: 1px solid #016ec2;
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 90%;

  .ant-picker-input > * {
    color: #016ec2;
  }

  .ant-picker-input > input::placeholder {
    color: #016ec2;
  }
`;

const TimeTitle = styled.h3`
  font-weight: normal;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #016ec2;
`;

const TimePicker = styled(TimePickerComponent)`
  border: 1px solid #016ec2;
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;
  letter-spacing: 0.2rem;
  width: 90%;
  margin-bottom: 10px;

  .ant-picker-input > * {
    color: #016ec2;
    letter-spacing: 0.2rem;
  }

  .ant-picker-input > input::placeholder {
    color: #016ec2;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media(max-width: 1024px) {
    width: 90%;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  
`;

const CheckboxGroup = styled(CheckboxComponent.Group)`
  > * {
    color: #016ec2;
  }
`;

const Checkbox = styled(CheckboxComponent)`
  color: #016ec2;
`;

const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DayOfTheWeekLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
const SwitchContainer = styled.div`
  width: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: flex-start; 
  gap: 20px;
`;

const TextContainer = styled.div`
  width: 100%; 
  display: flex; 
  justify-content: flex-start; 
  text-align: left;
`;

export {
  Card,
  Header,
  Type,
  Check,
  Title,
  Subtitle,
  Time,
  Image,
  DeleteButton,
  ButtonContainer,
  Icon,
  Text,
  MediaContainer,
  TimeInsertionsContainer,
  DateSection,
  DatePicker,
  TimePicker,
  DateContainer,
  DateSubContainer,
  TimeTitle,
  Label,
  DatePickerContainer,
  CheckboxContainer,
  CheckboxGroup,
  Checkbox,
  DayPickerContainer,
  DayOfTheWeekLabel,
  CardContent,
  SwitchContainer,
  TextContainer,
};
