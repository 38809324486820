import styled from 'styled-components';
import { Radio } from 'antd';

import Colors from '../../styles/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  width: 30%;
`;


export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 5px;
  color: ${Colors.secondaryBlue};
`;

export const DatesWrapper = styled.div`
  display: flex;
`;

export const RadioButton = styled(Radio.Button)`
  &.ant-radio-group-solid, &.ant-radio-button-wrapper-checked {
    background-color: ${Colors.secondaryBlue} !important;
    border-color: ${Colors.secondaryBlue} !important;
  }

  &:not(.ant-radio-button-wrapper-checked):hover {
    color: ${Colors.secondaryBlue} !important;
  }
`;
