import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Card,
  Container,
  DropContainer,
  Text,
  Image,
  ButtonContainer,
  Button,
  ButtonText,
  DropDownButton,
  DropDownContainer,
  InfoContainer,
  TopSeller,
} from './styles';
import { useAxios } from '../../services/api';
import { UserConsumer } from '../../state/UserContext';

import emailIcon from './Assets/email.svg';
import phoneIcon from './Assets/phone.svg';
import companyIcon from './Assets/bolsa.svg';
import deleteIcon from './Assets/delete.svg';
import createIcon from './Assets/create.svg';
import deleteBorderlessIcon from './Assets/ic-delete.svg';
import createBorderlessIcon from './Assets/ic-edit.svg';
import detailIcon from './Assets/+.svg';
import arrowIcon from './Assets/arrow.svg';
import WarnModal from '../WarnModal';
import { Modal } from '..';

const InfoText = ({ text, src, dropdown }) => (
  <Text black opacity={0.8} dropdown={dropdown}>
    <Image src={src} />
    {text}
  </Text>
);

const OptionButton = ({
  src,
  dropdown,
  active,
  onClick,
  text,
}) => (
  <Button onClick={onClick} active={active} dropdown={dropdown}>
    <Image noMargin src={src} />
    <ButtonText>
      {text}
    </ButtonText>
  </Button>
);

function CustomerCard({
  info
}) {
  const {
    name,
    email,
    phone,
    gender,
    birthday,
    createdAt,
    _id,
    totalPrice,
    topSellingProducts
  } = info;

  const [active, setActive] = useState(false);
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [showDropDown, setShowDropDown] = useState();
  const [mobile, setMobile] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    name,
    email,
    phone,
    gender,
    birthday,
    createdAt,
    _id,
    topSellingProducts,
    totalPrice,
  });
  const [axiosGet, axiosDelete] = useAxios('get', 'delete');

  const handleToggle = (event) => {
    if (mobile && event.target.tagName !== 'BUTTON') setShowDropDown(!showDropDown);
  };
  const handleEdit = () => setEditing(true);
  const handleDelete = () => setDeleting(true);

  const checkBreakpoint = () => setMobile(window.innerWidth < 600);

  useEffect(() => {
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, []);
  
  const getBrazilianCurrencyAmount = (value) => {
   
    const formattedAmount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  
    return formattedAmount;
  }
  if (deleted) return null;

  const createdDate = dayjs(customerInfo.createdAt).format('DD-MMM-YYYY HH:mm').toLowerCase();

  const MAX_SHOWN_PRODUCTS = 15;
  return (
    <Card
      onMouseOver={() => setActive(true)}
      onFocus={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={handleToggle}
    >
      <Container>
        <div style={{
          height: 'inherit',
          width: '100%',
          backgroundColor: 'white',
        }}
        >
          <div style={{ float: 'left', zIndex: 1 }}>
            <Text isTitle>
              {customerInfo.name}
            </Text>
            <Text black opacity={0.5} italic>
              {createdDate}
            </Text>
          </div>
          <DropDownButton>
            <Image src={arrowIcon} upside={showDropDown} />
          </DropDownButton>
        </div>
        <DropDownContainer show={showDropDown}>
          <div style={{ width: '100%' }}>
            <InfoText text={customerInfo.email} src={emailIcon} />
            <InfoText text={customerInfo.phone} src={phoneIcon} />
            <div style={{display: 'flex', width: 'fit-content'}}>
            <Text black opacity={0.3} italic>Valor Total </Text>
            <InfoText text={getBrazilianCurrencyAmount(customerInfo.totalPrice)} src={null} />
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
              <UserConsumer>
                {([loggedUserInfo]) => {
                  const { update, remove } = loggedUserInfo?.permissions?.user || {};
                  return (
                    <>
                      {update && (
                        <OptionButton freeWidth width="unset" onClick={handleEdit} src={detailIcon} dropdown />
                      )}
                      {remove && (
                        <OptionButton freeWidth width="unset" onClick={handleDelete} src={deleteBorderlessIcon} dropdown />
                      )}
                    </>
                  );
                }}
              </UserConsumer>
            </div>
        </DropDownContainer>
      </Container>
      {!showDropDown && (
        <>
          <DropContainer>
            <InfoText text={customerInfo.email} src={emailIcon} />
            <InfoText text={customerInfo.phone} src={phoneIcon} />
          </DropContainer>
          <DropContainer>
            <div style={{ width: '100%' }}>
              <Text>Valor Total </Text>
              <InfoText text={getBrazilianCurrencyAmount(customerInfo.totalPrice)} src={null} />
            </div>
          </DropContainer>
          <DropContainer>
            <div style={{ display: 'flex', width: '100%' }}>
              <UserConsumer>
                {([loggedUserInfo]) => {
                  const { update, remove } = loggedUserInfo?.permissions?.user || {};
                  return (
                    <>
                      {update && (
                        <OptionButton freeWidth width="unset" onClick={handleEdit} src={detailIcon} dropdown />
                      )}
                      {remove && (
                        <OptionButton freeWidth width="unset" onClick={handleDelete} src={deleteBorderlessIcon} dropdown />
                      )}
                    </>
                  );
                }}
              </UserConsumer>
            </div>
          </DropContainer>
        </>
      )}
      <td>
      <Modal active={editing} setActive={setEditing}>
          <Modal.Title>Mais Consumidos</Modal.Title>
          <InfoContainer flexStart overflow>
          { customerInfo?.totalPrice > 0 ?
          customerInfo?.topSellingProducts.slice(0, MAX_SHOWN_PRODUCTS).map((product, index) => {
            const { productName, quantity } = product;

            return (
              <TopSeller
                marginBottom
                key={productName}
                isTheLast={index + 1 === customerInfo?.topSellingProducts.slice(0, MAX_SHOWN_PRODUCTS).length}
              >
                <div>
                  <span>
                    {index + 1}
                    º
                  </span>
                  <p>{productName}</p>
                </div>

                <div>{quantity}</div>
              </TopSeller>
            );
          }) : 
          <h3>Nenhum produto foi consumido</h3>}
        </InfoContainer>
          
        </Modal>
      </td>
      <td>
        <WarnModal
          active={deleting}
          setActive={setDeleting}
          title="Excluir Consumidor?"
          text={(
            <>
              Tem certeza que deseja excluir o consumidor
              {' '}
              <span>{name}</span>
              ?
            </>
          )}
          accept={async () => {
            try {
              await axiosDelete({ url: `/customers/${_id}` });
              setDeleted(true);
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </td>
    </Card>
  );
}

export default CustomerCard;
