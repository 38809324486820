import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";

import Colors from '../../styles/Colors';

import {
  Card,
  Title,
  Text,
  Image,
  ButtonContainer,
  Button,
  Icon,
  Price,
  CompanySelect,
  CompanyOption,
  ModalContainer,
  Label,
  ImageInput,
  InputContainer,
} from './styles';
import { useAxios } from '../../services/api';
import WarnModal from '../WarnModal';
import { useUserInfo } from '../../state/UserContext';
import { useErrorContext } from '../../state/ErrorContext';

import { Modal } from '..';

import addIcon from './Assets/add.svg';
import deleteIcon from './Assets/delete.svg';
import imageIcon from './Assets/photo_plus.svg';
import imageInfoIcon from './Assets/photo_info.svg';

function ProductCard({
  _id,
  name,
  description,
  image,
  price,
  companyId,
  itemCompanyId,
  isItem,
  setProducts = () => null,
  itemId,
  updateInterface,
  setMergedItems = () => null,
  barcode,
  ncm,
  cst 
}) {
  const [deleting, setDeleting] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldModalShow, setShouldModalShow] = useState(false);
  const [inputName, setInputName] = useState('');
  const [inputDescription, setInputDescription] = useState('');
  const [inputBarCode, setInputBarCode] = useState('');
  const [inputImage, setInputImage] = useState(null);
  const [inputNcm, setInputNcm] = useState(null);
  const [inputCst, setInputCst] = useState(null);
  const [inputInfo, setInputInfo] = useState(null);
  const [inputCompany, setInputCompany] = useState('');

  const [isManager, setIsManager] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [, setError] = useErrorContext();

  const history = useHistory();

  const { pathname } = history?.location || {};

  useEffect(() => {
    setInputName(name);
    setInputDescription(description);
    setInputCompany(companyId);
    setInputBarCode(barcode);
    setInputNcm(ncm);
    setInputCst(cst); 
  }, [name, description]);

  const [axiosGet, axiosPost, axiosDelete, axiosPatch] = useAxios('get', 'post', 'delete', 'patch');

  const [itemPrice, setItemPrice] = useState(null);

  const [userInfo] = useUserInfo();

  useEffect(() => {
    if (!userInfo?.isAdmin || companies.length) return;

    const getCompanies = async () => {
      const { data: companiesData } = await axiosGet({ url: '/companies' });

      setCompanies(companiesData.data.map((company) => ({
        value: company._id,
        label: company.name,
      })));
    };

    getCompanies();
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) return;

    if (userInfo.isAdmin) return;

    const getRole = async () => {
      const { data: roleData } = await axiosGet({ url: `/roles/${userInfo.roleId}` });

      if (roleData.data.name === 'Gerente') {
        return setIsManager(true);
      }
    };

    getRole();
  }, [userInfo]);

  const isAdmin = userInfo?.isAdmin;

  const handleDelete = async () => {
    try {
      const response = await axiosDelete({ url: `/products/${_id}` });
      if (response.status === 200) {
        setDeleted(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setItemPrice((() => {
      const number = Number(String(price || '').replace(',', '.')).toFixed(2);

      return Math.ceil(number) ? number : null;
    })());
  }, [price]);

  useEffect(() => {
    if (!isItem) return;

    const savePrice = async () => {
      await axiosPatch({
        url: `/items/${itemId}`,
        body: {
          price: itemPrice,
        },
      });
    };

    savePrice();
  }, [itemPrice]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      inputName === ''
      || inputDescription === ''
      || inputCompany === ''
    ) {
      throw new Error('Campos faltando');
    }

    if (inputImage && !['image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(inputImage.type)) {
      throw new Error(`Tipo de arquivo (${inputImage.type}) não suportado`);
    }

    if (inputInfo && !['image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(inputInfo.type)) {
      throw new Error(`Tipo de arquivo (${inputInfo.type}) não suportado`);
    }

    if (inputBarCode && !(/^\d{13}$/.test(inputBarCode))) {
      throw new Error('Código de barras inválido');
    }

    if (inputName.length > 40) {
      throw new Error('O nome do produto não pode ter mais de 40 caracteres');
    }

    const data = new FormData();

    data.set('name', inputName);
    data.set('description', inputDescription);
    data.set('companyId', inputCompany);

    if (inputImage) {
      data.set('image', inputImage);
    }

    if (inputInfo) {
      data.set('nutritionalInfo', inputInfo);
    }

    if (inputBarCode) {
      data.set('barcode', inputBarCode);
    }
    if(inputNcm){
      data.set('ncm', inputNcm);
    }
    if(inputCst){
      data.set('cst',inputCst);
    }
    const { data: updatedProduct } = await axiosPatch({ url: `/products/${_id}`, body: data });
    
    setProducts((previousValue) => previousValue.map((value) => {
      if (value._id === _id) {
        const {
          name: updatedName,
          description: updatedDescription,
          image: updatedImage,
          nutritionalInfo: updatedInfo,
          companyId: updatedCompanyId,
        } = updatedProduct.data;

        return {
          ...value,
          name: updatedName,
          description: updatedDescription,
          image: updatedImage,
          nutritionalInfo: updatedInfo,
          companyId: updatedCompanyId,
        };
      }

      return value;
    }));
  };

  if (deleted) return null;

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Card
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Title onClick={() => (
        (
          userInfo?.isAdmin
          || (
            isManager
            && userInfo?.companyId === companyId
          )
        ) ? setShouldModalShow(true) : (pathname !== '/itens' && setError({
            type: 'Sem permissão',
            message: 'Não é possível editar um produto que não pertence à sua empresa',
          }))
      )}
      >
        {name}
      </Title>

      <Text onClick={() => (
        (userInfo?.isAdmin
          || (isManager
            && userInfo?.companyId === companyId
          )
        )
          ? setShouldModalShow(true) : (pathname !== '/itens' && setError({
            type: 'Sem permissão',
            message: 'Não é possível editar um produto que não pertence à sua empresa',
          }))
      )}
      >
        {description}

      </Text>

      <Image
        onClick={() => (
          (userInfo?.isAdmin
            || (isManager
              && userInfo?.companyId === companyId
            )
          )
            ? setShouldModalShow(true) : (pathname !== '/itens' && setError({
              type: 'Sem permissão',
              message: 'Não é possível editar um produto que não pertence à sua empresa',
            }))
        )}
        src={image}
      />

      <Modal
        handleSubmit={handleSubmit}
        active={shouldModalShow}
        setActive={setShouldModalShow}
      >
        <Modal.Title>Editar Produto</Modal.Title>

        <Modal.Input
          value={inputName}
          setValue={setInputName}
          placeholder="Nome do Produto"
        />

        <Modal.TextArea
          value={inputDescription}
          setValue={setInputDescription}
          placeholder="Descrição do Produto"
        />

        {userInfo?.isAdmin && (
          // <Modal.Select
          //   defaultValue={inputCompany}
          //   value={inputCompany}
          //   setValue={setInputCompany}
          //   options={companies}
          //   placeholder="Selecione a empresa"
          // />
          <CompanySelect value={inputCompany} onChange={setInputCompany}>
            {companies.map(({ label, value }) => (
              <CompanyOption
                value={value}
                style={{ color: Colors.secondaryBlue, opacity: 0.8 }}
              >
                {label}
              </CompanyOption>
            ))}

          </CompanySelect>
        )}

        <ImageInput setValue={setInputImage} placeholder="Imagem do Produto">
          <Icon src={imageIcon} />
        </ImageInput>

        {/* <Modal.FileInput setValue={setInputInfo} placeholder="Informações">
          <Icon src={imageInfoIcon} />
        </Modal.FileInput> */}
        <ModalContainer>
          <InputContainer>
            <Label>Código de Barras</Label>
            <InputMask
              mask="9999999999999"
              pattern="[0-9]*"
              maskChar=""
              type='tel'
              size="large"
              name="inputBarCode"
              placeholder=""
              value={inputBarCode}
              onChange={(e) => setInputBarCode(e.target.value)}
              >
              {(inputProps) => <Modal.Input {...inputProps} />}
            </InputMask>
          </InputContainer>
          <InputContainer>
            <Label>NCM</Label>
            <InputMask
              mask="99999999"
              pattern="[0-9]*"
              maskChar=""
              type='tel'
              size="large"
              name="ncm"
              placeholder=""
              value={inputNcm}
              onChange={(e) => setInputNcm(e.target.value)}
              >
              {(inputProps) => <Modal.Input {...inputProps} />}
              </InputMask>
            </InputContainer>
            <InputContainer>
              <Label>CST</Label>
              <InputMask
              mask="9999"
              pattern="[0-9]*"
              maskChar=""
              type='tel'
              size="large"
              name="cst"
              placeholder=""
              value={inputCst}
              onChange={(e) => setInputCst(e.target.value)}
              >
              {(inputProps) => <Modal.Input {...inputProps} />}
              </InputMask>
            </InputContainer>
          </ModalContainer>
        <Modal.Button>Finalizar</Modal.Button>
      </Modal>

      {isAdmin !== undefined && (
        <>
          {isAdmin ? (
            <ButtonContainer className="hover-button">
              <Button onClick={() => setDeleting(true)}>
                <Icon src={deleteIcon} />
                Excluir
              </Button>
            </ButtonContainer>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>

              <ButtonContainer className="hover-price-button">
                <Price
                  type="number"
                  value={itemPrice}
                  isHovered={isHovered}
                  onBlur={(event) => (!Number(event.target.value) ? setItemPrice('') : (
                    (() => {
                      const number = Number(String(itemPrice || '').replace(',', '.')).toFixed(2);

                      setItemPrice(Math.ceil(number) ? number : null);
                    })()
                  ))}
                  onChange={(e) => setItemPrice(e.target.value)}
                />
                <Button
                  onClick={async () => {
                    if (isItem) {
                      try {
                        await axiosDelete({ url: `/items/${itemId}` });

                        setProducts((previousValue) => previousValue.map((currentProduct) => {
                          if (currentProduct._id === _id) {
                            return {
                              ...currentProduct,
                              isItem: false,
                              price: 0,
                              itemCompanyId: '',
                            };
                          }

                          return currentProduct;
                        }));

                        setMergedItems((previousValue) => previousValue.filter((item) => (
                          item._id !== itemId
                        )));
                      } catch (error) {
                        console.error(error.message);
                      }
                    } else {
                      try {
                        const { status } = await axiosPost({
                          url: '/items',
                          body: {
                            price: itemPrice || 0,
                            companyId,
                            productId: _id,
                          },
                        });

                        if (status !== 201) return;

                        updateInterface();

                        setProducts((previousValue) => previousValue.map((currentProduct) => {
                          if (currentProduct._id === _id) {
                            return {
                              ...currentProduct,
                              isItem: true,
                              price: itemPrice || 0,
                              itemCompanyId,
                            };
                          }

                          return currentProduct;
                        }));
                      } catch (error) {
                        console.error(error.message);
                      }
                    }
                  }}
                >
                  {!isItem ? (
                    <>
                      <Icon src={addIcon} alt="Adicionar" />
                      Adicionar
                    </>
                  ) : (
                    <>
                      <Icon src={deleteIcon} alt="Excluir" />
                      Excluir
                    </>
                  )}
                </Button>
              </ButtonContainer>
            </div>
          )}
        </>
      )}

      <WarnModal
        active={deleting}
        setActive={setDeleting}
        title="Excluir Produto?"
        text={(
          <>
            Tem certeza que deseja excluir
            {' '}
            <span>{name}</span>
            {' '}
            da sua
            {' '}
            <span>lista de produtos?</span>
          </>
        )}
        accept={handleDelete}
      />
    </Card>
  );
}

export default ProductCard;
