import styled from 'styled-components';
import { Select } from 'antd';
import { Modal } from '..';
import Colors from '../../styles/Colors';

const { Option } = Select;

const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .3rem;
  height: max-content;
  /* overflow: hidden; */

  &:hover {
    outline: 1px solid ${Colors.blue};

    .hover-button {
      margin-top: -2.2rem;
      max-height: 2.2rem;
    }
    .hover-price-button {
      margin-top: -4.1rem;
      max-height: 4.1rem;
    }
  }
`;

const Title = styled.h3`
  color: ${Colors.secondaryBlack};
  font-weight: bolder;
  min-height: 1.3rem;
  width: 100%;
`;

const Text = styled.p`
  color: ${Colors.secondaryBlack};
  font-size: .7rem;
  width: 100%;
`;

const Image = styled.img`
  height: 6.3rem;
  margin: 1.5rem 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-height: 0rem;
  transition: max-height .3s ease-in-out, margin-top .3s ease-in-out;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    max-height: 2.2rem;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.blue};
  color: ${Colors.blue};
  border-radius: 5px;
  width: 100%;
  height: 35px;
  outline: none;
  background-color: white;
  padding: 0;
  margin: 0;
`;


const Icon = styled.img`
  margin: 0;
`;

const Price = styled.input`
  color: ${Colors.secondaryBlue};
  background-color: ${({ value, isHovered }) => (value || isHovered ? Colors.background : '#fff')};
  border: none;
  outline: none;
  text-align: center;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const CompanySelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;

  .ant-select-selector {
    border-radius: 5px !important;
    border-color: ${Colors.secondaryBlue} !important;
  }

  .ant-select-selection-item {
    flex: initial;
    color: ${Colors.secondaryBlue} !important;
  }

  & > * {
    color: ${Colors.secondaryBlue} !important;

  }
`;

const CompanyOption = styled(Option)`
  color: ${Colors.secondaryBlue} !important;
`;

const ModalContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-top: .5rem;
  @media (max-width: 1080px){
    display: block;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const Label = styled(Modal.Label)`
  margin-top: 0
`;

const ImageInput = styled(Modal.FileInput)`
  margin-bottom: .5rem;
`;

export {
  Card,
  Title,
  Text,
  Image,
  ButtonContainer,
  Button,
  Icon,
  Price,
  CompanySelect,
  CompanyOption,
  ModalContainer,
  Label,
  ImageInput,
  InputContainer,
};
