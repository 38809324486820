import React, { useState, useEffect } from 'react';
import { Radio, DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';

import { useAxios } from '../../services/api';
import Modal from '../Modal';
import { RadioButton, DateContainer, DatesWrapper } from './styles';

function DiscountModal({ active, setActive, groupId }) {
  const [discount, setDiscount] = useState(null);
  const [showDiscount, setShowDiscount] = useState({
    name: '',
    code: '',
    type: null,
    value: '',
    startDate: undefined,
    endDate: undefined,
  });

  const [axiosGet, axiosPatch] = useAxios('get', 'patch');

  useEffect(() => {
    if (discount) {
      setShowDiscount(discount);
    }
  }, [discount]);

  useEffect(() => {
    axiosGet({
      url: `/groups/${groupId}`,
      setState: setDiscount,
      process: (data) => data?.discount,
    });
  }, [groupId, axiosGet]);

  const getHandler = (key) => (e) => {
    const value = e?.target?.value || e;
    setShowDiscount((prevShowDiscount) => ({
      ...prevShowDiscount,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    if (discount) {
      const getDiff = (original, altered) => {
        const alteredEntries = Object.entries(altered)
          .filter(([key, value]) => (original[key] !== value));
        return Object.fromEntries(alteredEntries);
      };

      const changeObj = getDiff(discount, showDiscount);

      if (Object.keys(changeObj).length === 0) {
        throw new Error('Campos não foram alterados');
      }

      axiosPatch({
        url: `/groups/${groupId}`,
        body: { discount: showDiscount },
      });
    } else {
      if (
        !showDiscount.name
        || !showDiscount.code
        || !showDiscount.type
        || !showDiscount.value
        || !showDiscount.startDate
        || !showDiscount.endDate
      ) {
        throw new Error('Campos faltando');
      }
      axiosPatch({
        url: `/groups/${groupId}`,
        body: { discount: showDiscount },
      });
    }
  };

  return (
    <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
      <Modal.Title>Desconto</Modal.Title>
      <Modal.Input value={showDiscount.name} onChange={getHandler('name')} placeholder="Nome do desconto" />
      <Modal.Input value={showDiscount.code} onChange={getHandler('code')} placeholder="Código do desconto" />
      <Modal.Label>Tipo do desconto</Modal.Label>
      <Radio.Group buttonStyle="solid" value={showDiscount.type} onChange={getHandler('type')} style={{ margin: '10px' }}>
        <RadioButton value="Absoluto">Absoluto</RadioButton>
        <RadioButton value="Percentual">Percentual</RadioButton>
      </Radio.Group>
      <Modal.Input value={showDiscount.value} onChange={getHandler('value')} placeholder="Valor do desconto" />
      <ConfigProvider locale={locale}>
        <DatesWrapper>
          <DateContainer>
            Data Inicial
            <DatePicker
              defaultValue={moment(showDiscount.startDate)}
              onChange={(d) => getHandler('startDate')(d.toDate())}
              locale={locale}
              format="DD/MM/YYYY"
              placeholder="Data de começo"
            />
          </DateContainer>
          <DateContainer>
            Data Final
            <DatePicker
              defaultValue={moment(showDiscount.endDate)}
              onChange={(d) => getHandler('endDate')(d.toDate())}
              locale={locale}
              format="DD/MM/YYYY"
              placeholder="Data de fim"
            />
          </DateContainer>
        </DatesWrapper>
      </ConfigProvider>
      <Modal.Button>Modificar desconto</Modal.Button>
    </Modal>
  );
}

export default DiscountModal;
