import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router';

import {
  Group,
  Header,
  Button,
  Image,
  TitleContainer,
  Title,
  Dash,
} from './styles';
import { useAxios } from '../../services/api';
import { GroupLists, LazyLoader } from '../../components';
import NotFound from '../404';

import backIcon from './Assets/back.svg';

function GroupDetail({ location }) {
  const [redirect, setRedirect] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [group, setGroup] = useState({});

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [machines, setMachines] = useState([]);

  const [axiosGet] = useAxios('get');

  useEffect(() => {
    const navbarImg = document.querySelector('#logoM');
    navbarImg.style.display = 'none';
    return () => { navbarImg.style.display = 'block'; };
  }, []);


  useEffect(() => {
    const groupId = location.pathname.split('/')[2];
    axiosGet({
      url: `/groups/${groupId}`,
      setState: setGroup,
      success: setSuccess,
      error: setError,
    });
  }, [location.pathname, axiosGet]);

  useEffect(() => {
    if (group?.companyId) {
      axiosGet({
        url: `/companies/${group?.companyId}`,
        setState: setCompanyName,
        process: ({ name }) => name,
      });
    }
  }, [group?.companyId]);

  useEffect(() => {
    if (group?._id) {
      axiosGet({
        url: `/machines?filter=${JSON.stringify({ groupId: group?._id })}`,
        setState: setMachines,
      });
    }
  }, [group?._id, axiosGet]);

  const handleBackClick = async () => {
    setRedirect(`/empresas/${group?.companyId}`);
  };

  if (redirect) return <Redirect to={redirect} />;

  if (!error && !success) return <LazyLoader page />;

  if (error) return <NotFound />;

  return (
    <Group>
      <Header>
        <Button type="button" onClick={handleBackClick}>
          <Image src={backIcon} />
        </Button>
        <TitleContainer>
          <Title>{companyName}</Title>
          <Dash />
        </TitleContainer>
      </Header>
      <GroupLists
        companyId={group?.companyId}
        groupName={group?.name || ''}
        groupId={location.pathname.split('/')[2]}
        headerTabs={[`Operações (${machines.length})`, 'Time']}
      />
    </Group>
  );
}

export default withRouter(GroupDetail);
