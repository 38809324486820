/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useRef,
  memo,
} from 'react';
import { Switch } from 'antd';
import moment from 'moment';

import {
  Card,
  Header,
  Type,
  Check,
  Title,
  Subtitle,
  Time,
  Image,
  DeleteButton,
  ButtonContainer,
  Text,
  Icon,
  MediaContainer,
  TimeInsertionsContainer,
  DateContainer,
  DateSubContainer,
  DateSection,
  DatePicker,
  TimePicker,
  TimeTitle,
  Label,
  DatePickerContainer,
  CheckboxContainer,
  CheckboxGroup,
  Checkbox,
  DayPickerContainer,
  DayOfTheWeekLabel,
  CardContent,
  SwitchContainer,
  TextContainer,
} from './styles';
import WarnModal from '../WarnModal';
import Modal from '../Modal';
import { useAxios } from '../../services/api';
import stringifyTime from '../../utils/stringifyTime';
import { useUserInfo } from '../../state/UserContext';
import { useErrorContext } from '../../state/ErrorContext';

import checkmarkIcon from './assets/checkmark.svg';
import clockIcon from './assets/clock.svg';
import deleteIcon from './assets/delete.svg';
import imageIcon from './assets/photo_plus.svg';
import videoIcon from './assets/video.svg';

function AdCard({
  _id,
  type,
  name,
  media,
  verified,
  companyId,
  displayTime,
  insertions,
  startDate: propsStartDate,
  endDate: propsEndDate,
  startTime: propsStartTime,
  endTime: propsEndTime,
  daysOfWeek,
}) {
  const [deleted, setDeleted] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [initialStartString, setInitialStartString] = useState({});
  const [initialEndString, setInitialEndString] = useState('');
  const [initialStartTimeString, setInitialStartTimeString] = useState({});
  const [initialEndTimeString, setInitialEndTimeString] = useState('');

  const dayOfTheWeekOptions = [
    {label: "Dom", value: 0},
    {label: "Seg", value: 1},
    {label: "Ter", value: 2},
    {label: "Qua", value: 3},
    {label: "Qui", value: 4},
    {label: "Sex", value: 5},
    {label: "Sab", value: 6},
  ];

  const startingCheckedList = daysOfWeek.length === 0 ? dayOfTheWeekOptions.map(option => option.value) : daysOfWeek;
  const [shouldPersonalizeShow, setShouldPersonalizeShow] = useState(true);
  const [wholeDay, setWholeDay] = useState(propsStartTime === propsEndTime);
  const [checkedList, setCheckedList] = useState(startingCheckedList);
  const [indeterminate, setIndeterminate] = useState(daysOfWeek.length !== 0 && daysOfWeek.length !== 7);
  const [checkAll, setCheckAll] = useState(daysOfWeek.length === 7 || daysOfWeek.length === 0);

  const handleCheckboxSelection = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < dayOfTheWeekOptions.length);
    setCheckAll(list.length === dayOfTheWeekOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? dayOfTheWeekOptions.map(option => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const dateFormat = 'DD/MM/YYYY';
  const timeFormat = 'HH:mm';

  useEffect(() => {
    if (propsStartTime === null) { 
      const startDateTime = new Date(propsStartDate);
      const hours = startDateTime.getHours()  < 10 ? "0" + startDateTime.getHours() : startDateTime.getHours();
      const minutes = startDateTime.getMinutes()  < 10 ? "0" + startDateTime.getMinutes() : startDateTime.getMinutes();
      setStartTime(hours + ":" + minutes);
    } else {
      setStartTime(propsStartTime);
    }
    if (propsEndTime === null) { 
      const endDateTime = new Date(propsEndDate);
      const hours = endDateTime.getHours()  < 10 ? "0" + endDateTime.getHours() : endDateTime.getHours();
      const minutes = endDateTime.getMinutes()  < 10 ? "0" + endDateTime.getMinutes() : endDateTime.getMinutes();
      setEndTime(hours + ":" + minutes);
    } else {
      setEndTime(propsEndTime);
    }

    setStartDate(propsStartDate);
    setEndDate(propsEndDate);

    if (propsStartDate) {
      const date = new Date(propsStartDate);
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      setInitialStartString({
        value: moment(`${dd / 10 >= 1 ? dd : `0${dd}`}/${mm / 10 >= 1 ? mm : `0${mm}`}/${yyyy}`, dateFormat),
      });
      if (propsStartTime === null) {
        setInitialStartTimeString({
          value: moment(`${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}`, timeFormat),
        });
      } else {
        setInitialStartTimeString({
          value: moment(propsStartTime, timeFormat),
        })
      }
    }

    if (propsEndDate) {
      const date = new Date(propsEndDate);
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      setInitialEndString({
        value: moment(`${dd / 10 >= 1 ? dd : `0${dd}`}/${mm / 10 >= 1 ? mm : `0${mm}`}/${yyyy}`, dateFormat),
      });

      if (propsEndTime === null) {
        setInitialEndTimeString({
          value: moment(`${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}`, timeFormat),
        });
      } else {
        setInitialEndTimeString({
          value: moment(propsEndTime, timeFormat),
        })
      }
    }
  }, [propsStartDate, propsEndDate]);
  const [userInfo] = useUserInfo();

  const [axiosGet, axiosPatch, axiosDelete] = useAxios('get', 'patch', 'delete');

  const [, setError] = useErrorContext();

  const adTypes = {
    pageTop: 'Topscreen',
    fullscreen: 'Fullscreen',
    billboard: 'Billboard',
  };

  const [adInfo, setAdInfo] = useState({
    name,
    type: { value: type, label: adTypes[type] },
    company: null,
    media: null,
    verified,
  });

  const [displayAd, setDisplayAd] = useState({
    name,
    type: adTypes[type],
    company: '',
    verified: null,
  });

  const [companyOptions, setCompanyOptions] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);

  const [controls, setControls] = useState(false);
  const videoRef = useRef(null);

  const reloadCard = async () => {
    const adRes = await axiosGet({ url: `/ads/${_id}` });
    const ad = adRes.data.data;
    const companyRes = await axiosGet({ url: `/companies/${ad.companyId}` });

    setAdInfo({
      ...adInfo,
      name: ad.name,
      type: { value: ad.type, label: adTypes[ad.type] },
      company: { value: ad.companyId, label: companyRes.data.data.name },
      verified: ad.verified,
    });
    setDisplayAd({
      name: ad.name,
      type: adTypes[ad.type],
      company: companyRes.data.data.name,
      verified: ad.verified,
    });

    if (ad.media.indexOf('.mp4') === -1) {
      setImageSrc(ad.media);
    } else {
      setVideoSrc(ad.media);
    }
  };

  const handleSubmit = async () => {
    if (adInfo.media) {
      if (!['image/jpeg', 'image/png', 'video/mp4',].includes(adInfo.media.type)) {
        throw new Error(`Tipo de arquivo (${adInfo.media.type}) não suportado`);
      }
      if (adInfo.media.size >= 200000000) { // 200MB
        throw new Error(
          `O arquivo tem ${(adInfo.media.size / 1000000).toFixed(2)}MB, o máximo permitido é 200MB`,
        );
      }
    }

    const data = new FormData();

    const adRes = await axiosGet({ url: `/ads/${_id}` });
    const currentAd = adRes.data.data;

    const getDate = (specificDate) => {
      if (specificDate) {
        const completeSpecificDate = new Date(specificDate);

        completeSpecificDate.setSeconds(0);
        completeSpecificDate.setHours(0);
        completeSpecificDate.setMinutes(0);

        return completeSpecificDate;
      }

      return null;
    };

    const finalStartDate = getDate(startDate);
    const finalEndDate = getDate(endDate);
    const finalStartTime = startTime === null ? "00:00" : startTime;
    const finalEndTime = endTime === null ? "00:00" : endTime;

    data.append('startDate', finalStartDate);
    data.append('endDate', finalEndDate);
    data.append('startTime', finalStartTime);
    data.append('endTime', finalEndTime);
    data.append('daysOfWeek', checkedList);


    if (adInfo.name !== currentAd.name) {
      data.set('name', adInfo.name);
    }
    if (adInfo.type.value !== currentAd.type) {
      data.set('type', adInfo.type.value);
    }
    if (adInfo.company.value !== currentAd.companyId) {
      data.set('companyId', adInfo.company.value);
    }
    if (adInfo.verified !== currentAd.verified) {
      data.set('verified', adInfo.verified);
    }
    if (adInfo.media) {
      data.set('media', adInfo.media);
    } else {
      data.set('media', media);
    }

    if (data.has('type') && data.get('type') !== 'billboard' && !data.has('media')) {
      throw new Error('Preencha o campo de mídia');
    }

    if (
      data.has('name')
      || data.has('type')
      || data.has('companyId')
      || data.has('verified')
      || data.has('startDate')
      || data.has('endDate')
      || data.has('startTime')
      || data.has('endTime')
      || data.has('daysOfWeek')
      || adInfo.media 
    ) {
      await axiosPatch({ url: `/ads/${_id}`, body: data, success: reloadCard });
    } else {
      throw new Error('Os campos não foram alterados');
    }
  };

  useEffect(() => {
    if (media.indexOf('.mp4') === -1) {
      setImageSrc(media);
    } else {
      setVideoSrc(media);
    }
  }, [media]);

  useEffect(() => {
    axiosGet({
      url: `/companies/${companyId}`,
      setState: setDisplayAd,
      process: (adCompany) => {
        setAdInfo((info) => ({
          ...info,
          company: { value: companyId, label: adCompany.name },
        }));
        return (ad) => ({
          ...ad,
          company: adCompany.name,
        });
      },
    });
  }, [companyId, axiosGet]);

  useEffect(() => {
    if (userInfo?.isAdmin) {
      axiosGet({
        url: '/companies',
        setState: setCompanyOptions,
        process: (data) => data.map((eachCompany) => ({
          value: eachCompany._id,
          label: eachCompany.name,
        })),
      });
    }
  }, [axiosGet, userInfo]);

  useEffect(() => {
    if (displayAd.verified !== null) {
      axiosPatch({ url: `/ads/${_id}`, body: { verified: displayAd.verified } });
      setAdInfo((info) => ({
        ...info,
        verified: displayAd.verified,
      }));
    }
  }, [displayAd.verified, _id, axiosPatch]);

  const handleVideoClick = () => {
    videoRef.current.play();
    setControls(true);
  };

  if (deleted) return null;

  const isBillboard = adInfo.type?.value === 'billboard';
  const { update, remove } = userInfo?.permissions.ad || {};
  
  return (
    <Card onClick={(e) => {
      const { tagName, className, id } = e.target;
      if (
        tagName !== 'BUTTON'
        && tagName !== 'IMG'
        && tagName !== 'INPUT'
        && tagName !== 'SPAN'
        && tagName !== 'DIV'
        && tagName !== 'VIDEO'
        && !className.split(' ').includes('delete-button')
        && id !== 'shade'
        && update
      ) {
        setEditing(true);
      }
      if (className.split(' ').includes('image') && update) setEditing(true);
    }}
    >
      <div style={{ width: '100%' }}>
        <Header>
          <Type>{displayAd.type}</Type>
          {userInfo && 
            <Switch
              size="small"
              checked={displayAd.verified !== null ? displayAd.verified : verified}
              onChange={(value) => setDisplayAd({
                ...displayAd,
                verified: value,
              })}
            />
            }
        </Header>
        <Title isText={(displayAd.type === 'Billboard')}>{displayAd.name}</Title>
        <Subtitle>{displayAd.company}</Subtitle>
        <TimeInsertionsContainer>
          <Time>
            <Check style={{ margin: '.3rem .5rem .3rem 0' }} src={clockIcon} />
            {stringifyTime(displayTime || 0)}
          </Time>
          <Time>{`${insertions} ${insertions > 1 ? 'inserções' : 'inserção'}`}</Time>
        </TimeInsertionsContainer>
      </div>
      <CardContent>
        {!(displayAd.type === 'Billboard') && (
          <MediaContainer>
            {imageSrc && <Image className="image" src={imageSrc} />}
            {videoSrc && (
              <video
                poster={videoIcon}
                onClick={handleVideoClick}
                style={{ height: '6rem' }}
                width="100%"
                preload="none"
                controls={controls}
                ref={videoRef}
                loop
              >
                <source src={videoSrc} type="video/mp4" />
              </video>
            )}
          </MediaContainer>
        )}
        {remove && (
          <ButtonContainer className="delete-button">
            <DeleteButton onClick={() => setDeleting(true)}>
              <Check src={deleteIcon} />
              Excluir
            </DeleteButton>
          </ButtonContainer>
        )}
        <Modal handleSubmit={handleSubmit} active={editing} setActive={setEditing}>
          <Modal.Title>Edição de Anúncio</Modal.Title>
          {isBillboard ? (
            <Modal.TextArea
              value={adInfo.name}
              setValue={(value) => setAdInfo({ ...adInfo, name: value })}
              placeholder="Texto do Billboard"
            />
          ) : (
            <Modal.Input
              value={adInfo.name}
              setValue={(value) => setAdInfo({ ...adInfo, name: value })}
              placeholder="Nome do Anúncio"
            />
          )}
          <Modal.Select
            options={[
             // { value: 'pageTop', label: 'Topscreen' },
              { value: 'fullscreen', label: 'Fullscreen' },
              // { value: 'billboard', label: 'Billboard' },
            ]}
            value={adInfo.type}
            setValue={(value) => setAdInfo({ ...adInfo, type: value })}
            placeholder="Tipo do Anúncio"
          />
          {userInfo?.isAdmin && (
            <Modal.Select
              options={companyOptions}
              value={adInfo.company}
              setValue={(value) => setAdInfo({ ...adInfo, company: value })}
              placeholder="Selecione a empresa"
            />
          )}
          {adInfo.type?.value !== 'billboard' && (
            <>
              <Modal.FileInput setValue={(value) => setAdInfo({ ...adInfo, media: value })} placeholder="Mídia do Anúncio">
                <Icon src={imageIcon} />
              </Modal.FileInput>
              <TextContainer>
              <Text italic width="100%" opacity={0.6} black>
                <br></br>
                      Formatos aceitos: 
                      {' '}
                      <strong>jpeg</strong>
                      ,
                      {' '}
                      <strong>png</strong>
                      {' '}
                      ou
                      {' '}
                      <strong>mp4</strong>
                      . Tamanho máximo do arquivo: 
                      {' '}
                      <strong>200 mb.</strong>
                      <br></br>
                      Resolução máxima dos vídeos:
                      {' '}
                      <strong>Full HD.</strong> 
                  <br></br>
              </Text>
              </TextContainer>
            </>
          )}
            <SwitchContainer>
              <Text width="min-content">Ativado</Text>
              <Switch
                size= "small"
                checked={adInfo.verified}
                onChange={(value) => setAdInfo({ ...adInfo, verified: value })}
              />
            </SwitchContainer>

          <DateSection>
            <TimeTitle>Programação do anúncio</TimeTitle>
            <DateContainer>
              <DateSubContainer>
                <DatePickerContainer>
                  <Label>Data Inicial:</Label>
                  <DatePicker
                    locale="pt-BR"
                    format={dateFormat}
                    {...initialStartString}
                    placeholder="dd/mm/aaaa"
                    onChange={(e) => {
                      setStartDate(e);

                      const updatedDate = new Date(e);
                      const dd = updatedDate.getDate();
                      const mm = updatedDate.getMonth() + 1;
                      const yyyy = updatedDate.getFullYear();

                      if (!e) {
                        return setInitialStartString({});
                      }

                      return setInitialStartString({
                        value: moment(`${dd / 10 >= 1 ? dd : `0${dd}`}/${mm / 10 >= 1 ? mm : `0${mm}`}/${yyyy}`, dateFormat),
                      });
                    }}
                  />
                </DatePickerContainer>
                <DatePickerContainer>
                  <Label>Data Final:</Label>
                  <DatePicker
                    format={dateFormat}
                    {...initialEndString}
                    placeholder="dd/mm/aaaa"
                    onChange={(e) => {
                      setEndDate(e);

                      const updatedDate = new Date(e);
                      const dd = updatedDate.getDate();
                      const mm = updatedDate.getMonth() + 1;
                      const yyyy = updatedDate.getFullYear();

                      if (!e) {
                        return setInitialEndString({});
                      }

                      return setInitialEndString({
                        value: moment(`${dd / 10 >= 1 ? dd : `0${dd}`}/${mm / 10 >= 1 ? mm : `0${mm}`}/${yyyy}`, dateFormat),
                      });
                    }}
                  />
                </DatePickerContainer>
                <Checkbox value={shouldPersonalizeShow} checked={shouldPersonalizeShow} onChange={() => setShouldPersonalizeShow(!shouldPersonalizeShow)}>Personalizar</Checkbox>
              </DateSubContainer>
              {shouldPersonalizeShow && (
              <>
                <DateSubContainer>
                  <DatePickerContainer>
                    <Label>Horário Inicial:</Label>
                    <TimePicker
                      format="HH:mm"
                      placeholder="__:__"
                      minuteStep={15}
                      onChange={(e, timeString) => {
                        setStartTime(timeString);

                        const updatedDate = new Date(e);
                        const hours = updatedDate.getHours();
                        const minutes = updatedDate.getMinutes();

                        if (!e) {
                          return setInitialStartTimeString({});
                        }

                        return setInitialStartTimeString({
                          value: moment(`${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}`, timeFormat),
                        });
                      }}
                      disabled={wholeDay}
                      {...initialStartTimeString}
                    />
                  </DatePickerContainer>
                  <DatePickerContainer>
                    <Label>Horário Final:</Label>
                    <TimePicker
                      format="HH:mm"
                      placeholder="__:__"
                      minuteStep={15}
                      onChange={(e, timeString) => {
                        setEndTime(timeString);

                        const updatedDate = new Date(e);
                        const hours = updatedDate.getHours();
                        const minutes = updatedDate.getMinutes();

                        if (!e) {
                          return setInitialEndTimeString({});
                        }

                        return setInitialEndTimeString({
                          value: moment(`${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}`, timeFormat),
                        });
                      }}
                      disabled={wholeDay}
                      {...initialEndTimeString}
                    />
                  </DatePickerContainer>
                  <Checkbox value={wholeDay} onChange={() => setWholeDay(!wholeDay)} checked={wholeDay}>Dia Inteiro</Checkbox>
                </DateSubContainer>
                <DayPickerContainer>
                  <DayOfTheWeekLabel>
                    <Label>Dias de Exibição:</Label>  
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                          Todos
                    </Checkbox>
                  </DayOfTheWeekLabel>
                  <CheckboxContainer>
                    <CheckboxGroup value={checkedList} options={dayOfTheWeekOptions} onChange={handleCheckboxSelection} />
                  </CheckboxContainer>
                </DayPickerContainer>
              </>)}
            </DateContainer>
          </DateSection>

          <Modal.Button>Finalizar</Modal.Button>
        </Modal>
        <WarnModal
          active={deleting}
          setActive={setDeleting}
          title="Excluir Anúncio?"
          text={(
            <>
              Tem certeza que deseja excluir o anúncio
              {' '}
              <span>{name}</span>
              ?
            </>
          )}
          accept={async () => {
            try {
              const response = await axiosDelete({ url: `/ads/${_id}`, cancelAlert: true });

              const { config, data, status } = response.data ? response : response.response;

              const { message, data: innerData } = data || {};
              const { url: deleteUrl, method } = config;

              if (status === 200) {
                setDeleted(true);
              } else if (status === 400) {
                if (message === 'You cannot delete items that are referenced by other items') {
                  const machines = await Promise.all(innerData.map(({ id: machineId }) => axiosGet({ url: `/machines/${machineId}` })));

                  const info = `Máquinas usando anúncio:\n${machines.map(({ data: machineResponse }) => machineResponse.data.name).join(', ')}`;
                  setError({
                    type: method,
                    title: 'Não é possível deletar um anúncio usado em uma máquina.',
                    message: info,
                    status,
                    url: deleteUrl,
                  });
                }
              }
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </CardContent>
    </Card>
  );
}

export default memo(AdCard);
